<script setup lang="ts">
import { cva } from 'class-variance-authority';

enum Colors {
  White = 'white',
  Gray = 'gray'
}

interface Props {
  level: string | number;
  color?: Colors;
  className?: string;

  [x: string]: any;
}

const props = withDefaults(defineProps<Props>(), {
  color: Colors.Gray
});

const levelIconName = computed(() => {
  return `levels/${props.level}`;
});

const levelClass = computed(() => {
  return cva(['pl-2.5', props.className], {
    variants: {}
  })({});
});
</script>
<template>
  <Tag :color="color" :class-name="levelClass">
    <NuxtIcon :name="levelIconName" filled class="mr-1" />
    <span>Level {{ level }}</span>
  </Tag>
</template>

<style lang="scss" scoped>
:deep(.nuxt-icon svg) {
  @apply mb-0;
}
</style>
